body {
  margin: 0;
  font-family: ChineseTakeaway;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.readable-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  html {
    font-size: 90%;  /* Reduces to 14.4px if default is 16px */
  }
}


@keyframes blink {
  from {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
}

@font-face {
  font-family: 'ChineseTakeaway';
  src: local('ChineseTakeaway'), url(./fonts/Chinese-Takeaway.TTF) format('truetype');
}

/* CSS Reset */
/* https://www.joshwcomeau.com/css/custom-css-reset/ */
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root {
  isolation: isolate;
}
